@font-face {
  font-family: 'Museo';
  src: url('../assets/font/Museo300-Regular-webfont.woff2') format('woff2'),
    url('../assets/font/Museo300-Regular-webfont.woff') format('woff');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src: url('../assets/font/Museo500-Regular-webfont.woff2') format('woff2'),
    url('../assets/font/Museo500-Regular-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src: url('../assets/font/Museo700-Regular-webfont.woff2') format('woff2'),
    url('../assets/font/Museo700-Regular-webfont.woff2') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/font/Helvetica.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/font/Helvetica-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Check out https://ui.shadcn.com/docs/theming for more info */
/* Also https://tailwindcss.com/docs/customizing-colors#using-css-variables */
@layer base {
  :root {
    --background: 178 41% 88%;
    --background-light: 175 45% 95%;
    --foreground: 189 100% 24%;
    --plain: 0 0% 100%;
    --plain-foreground: 222.2 47.4% 11.2%;
    --overlay: 0 0% 10% / 60%;

    --muted: 0 0% 88%;
    --muted-foreground: 0 0% 0%;

    --popover: 0 0% 100%;
    --popover-foreground: 175 48% 95%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --card-active: 188 100% 97%;
    --card-hover: 180 58% 96%;

    --border-danger: 334 50% 46%;
    --border-primary: 186, 100%, 37%;
    --border-card: 189 100% 27%;
    --border-info: 188 44% 58%;
    --border: 214.3 31.8% 91.4%;
    --border-input: 0 0% 85%;
    --input: 0 0% 100%;

    --switch-disabled: 0 0% 85%;

    --primary: 189 100% 24%;
    --primary-foreground: 210 40% 98%;
    --primary-dark: 189 100% 20%;
    --primary-light: 177 42% 80%;
    --primary-lightest: 180 40% 83%;

    --primary-muted: 176 25% 88%;
    --primary-muted-foreground: 0 0% 27%;

    --secondary: 187 45% 51%;
    --secondary-foreground: 0 0% 97%;

    --accent: 283 29% 42%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 100% 52%;
    --destructive-foreground: 210 40% 98%;

    --background-warning: 31 66% 92%;
    --background-warning-foreground: 222.2 47.4% 11.2%;

    --danger: 343 78% 93%;
    --danger-foreground: 331 90% 28%;

    --warning: 16 100% 66%;
    --warning-foreground: 210 40% 98%;

    --success: 120 82% 27%;
    --success-foreground: 129 90% 90%;

    --info: 188 100% 94%;
    --info-foreground: 202 100% 39%;

    --pale: 185 38% 94%;
    --pale-foreground: 0 0% 30%;

    --text-helper: 0 0% 40%;

    --ring: 204 100% 37%;

    --text-body: 0 0% 20%;

    --radius: 0.5rem;
    --font-sans: 'Helvetica';
    --font-museo: 'Museo';

    --flags-dairy-free: 268 73% 49%;
    --flags-gluten-free: 37 73% 52%;
    --flags-vegetarian: 120 82% 27%;
    --flags-halal: 209 77% 34%;

    /* Custom Swiper styling */
    --swiper-pagination-color: hsl(var(--primary));
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-inactive-color: hsl(var(--muted));
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-vertical-gap: 6px;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer utilities {

  /* Hide arrows for number input */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

/* Custom css for chatbot */

.zsiq_custommain img {
  width: 110px !important;
  height: auto !important;
}

.zsiq_custommain {
  width: auto !important;
  height: auto !important;
}