

:root {

  /* Custom CK styles */
  /* --ck-powered-by-line-height: 0px;
  --ck-powered-by-padding-vertical: 2px;
  --ck-powered-by-padding-horizontal: 4px;
  --ck-powered-by-text-color: hsl(var(--primary));
  --ck-powered-by-border-radius: 100px;
  --ck-powered-by-background: hsl(0, 0%, 100%);
  --ck-powered-by-border-color: var(--ck-color-focus-border); */
/* 
  .ck-powered-by {
    display: none;
  } */
  /* .ck-placeholder: {
    color: hsl(var(--text-helper));
  } */

  /* .ck {

    ol li  {
      list-style-type: decimal;
    }
    ul li {
      list-style-type: disc;
    }
  } */
  .ck-editor__editable_inline:not(.ck-comment__input *) {
      min-height: 80px;
      max-height:400px;
      overflow-y: auto;
  }
  --ck-color-button-on-background: hsl(var(--primary-light));
  --ck-color-button-on-active-background: hsl(var(--background-light));
  --ck-color-button-on-hover-background: hsl(var(--background));
  --ck-color-button-on-color: hsl(var(--primary));
  --ck-color-base-border: hsl(var(--border-input));
  --ck-color-focus-border: hsl(var(--primary));
  --ck-color-on-text: hsl(var(--primary));
  --ck-color-hover-border: hsl(var(--primary));
  --ck-placeholder: hsl(var(--text-helper));
  --ck-border-radius: 8px;
}